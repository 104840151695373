<template>
    <div class="d-flex flex-wrap flex-stack my-5 justify-content-end">
        <el-tooltip :content="$t('pages.ecommerce.discountCoupon.exportTooltip')" popper-class="max-w-300px" placement="bottom">
            <button class="btn btn-danger align-self-center ms-3" :data-kt-indicator="exportLoading ? 'on' : null" :disabled="exportLoading" v-on:click="exportDiscount">
                <span v-if="!exportLoading" class="indicator-label">
                    <span class="svg-icon svg-icon-1">
                        <inline-svg src="/media/icons/duotune/files/fil021.svg"/>
                    </span>
                    {{ $t("common.export") }}
                </span>

                <span v-if="exportLoading" class="indicator-progress">
                        <span class="spinner-border spinner-border-sm align-middle me-2"></span>
                        {{ $t("messages.wait") }}
                    </span>
            </button>
        </el-tooltip>
    </div>
    <custom-table
        :title="$t('pages.ecommerce.discountCoupon.title')"
        :subTitle="$t('pages.ecommerce.discountCoupon.subTitle')"
        rowKey="id"
        :items="table.data"
        :loading="table.loading"
        selectableRows
        :columns="fields"
        :actions="actions"
        :pagination="table.pagination"
        filterComponentName="EcommerceDiscountCouponFilter"
        @action="handleClickAction"
        @selectableRows="handleSelectedRow"
        @changeTable="handleTableChange">
        <template v-slot:type="{ row: record }">
            {{ $t('pages.ecommerce.discountCoupon.couponTypes.' + types[record.discount_type]) }}
        </template>
        <template v-slot:value="{ row: record }">
            {{ record.discount_value + (record.discount_type == 2 ? '%' : '') }}
        </template>
        <template v-slot:start_date="{ row: record }">
            {{ record.start_date ? $moment(record.start_date).format("DD.MM.YYYY - HH:mm:ss") : "-" }}
        </template>
        <template v-slot:end_date="{ row: record }">
            {{ record.end_date ? $moment(record.end_date).format("DD.MM.YYYY - HH:mm:ss") : "-" }}
        </template>
        <template v-slot:status="{ row: record }">
            <span :class="record.active ? 'badge-light-success': 'badge-light-danger'" class="badge">{{ record.active ? $t('common.active') : $t('common.passive') }}</span>
        </template>
        <template v-slot:created_at="{ row: record }">
            {{ $moment(record.created_at).format("DD.MM.YYYY - HH:mm:ss") }}
        </template>
        <template v-slot:actions="{ row: record }">
            <div class="d-flex justify-content-end">
                <a v-on:click="fetchDiscountCoupon(record)" class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1">
                    <span class="svg-icon svg-icon-3">
                        <inline-svg src="/media/icons/duotune/art/art005.svg"/>
                    </span>
                </a>
                <el-popconfirm :title="$t('messages.sureDelete')" :confirm-button-text="$t('btn.yes')" :cancel-button-text="$t('btn.no')" @confirm="deleteRecord([record.id])">
                    <template #reference>
                        <a class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm">
                            <span class="svg-icon svg-icon-3">
                                <inline-svg src="/media/icons/duotune/general/gen027.svg"/>
                            </span>
                        </a>
                    </template>
                </el-popconfirm>
            </div>
        </template>
    </custom-table>
    <div class="modal fade" id="kt_modal_add_discount_coupon" ref="addDiscountCoupon" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered mw-650px">
            <div class="modal-content">
                <div class="modal-header" id="kt_modal_add_customer_header">
                    <h2 class="fw-bolder">{{ form.title }}</h2>
                    <div id="kt_modal_add_customer_close" data-bs-dismiss="modal" class="btn btn-icon btn-sm btn-active-icon-primary">
                        <span class="svg-icon svg-icon-1">
                            <inline-svg src="/media/icons/duotune/arrows/arr061.svg"/>
                        </span>
                    </div>
                </div>
                <el-form @submit.prevent="onSubmit()" :model="form.data" ref="discountCouponForm">
                    <div class="modal-body py-10 px-lg-17">
                        <div class="me-n7 pe-7 scroll-y" id="kt_modal_add_customer_scroll" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_modal_add_customer_header" data-kt-scroll-wrappers="#kt_modal_add_customer_scroll" data-kt-scroll-offset="300px">
                            <template v-if="!form.updateStatus">
                                <div class="fv-row mb-7">
                                    <label class="required fs-6 fw-bold mb-2">{{ $t('pages.ecommerce.discountCoupon.cols.type') }}</label>
                                    <el-form-item prop="type" :rules="$validation.getMessage(['required'])">
                                        <el-select v-model="form.data.type" class="w-100" :placeholder="$t('common.chooseSelect')">
                                            <el-option value="SINGLE" :label="$t('pages.ecommerce.discountCoupon.types.single')"></el-option>
                                            <el-option value="MULTI" :label="$t('pages.ecommerce.discountCoupon.types.multi')"></el-option>
                                        </el-select>
                                    </el-form-item>
                                </div>
                                <div class="fv-row mb-4 code-form-row" v-if="form.data.type == 'SINGLE'">
                                    <label class="required fs-6 fw-bold mb-2">{{ $t('pages.ecommerce.discountCoupon.cols.code') }}</label>
                                    <el-form-item prop="code" :rules="$validation.getMessage(['required'])">
                                        <el-input v-model="form.data.code" type="text" v-mask="{casing:'upper', regex: '^[a-zA-Z0-9 ]+$'}"/>
                                        <a class="fw-bold text-muted text-hover-primary fs-7 cursor-pointer" v-on:click="generateCode">
                                            <div class="d-flex align-items-center">
                                                <span class="svg-icon svg-icon-2 me-1">
                                                    <inline-svg src="/media/icons/duotune/arrows/arr029.svg"/>
                                                </span>
                                                {{ $t('pages.ecommerce.discountCoupon.generateCode') }}
                                            </div>
                                        </a>
                                    </el-form-item>
                                </div>
                                <div class="fv-row mb-7" v-if="form.data.type == 'MULTI'">
                                    <label class="fs-6 fw-bold mb-2">{{ $t('pages.ecommerce.discountCoupon.cols.count') }}</label>
                                    <el-form-item prop="count" :rules="$validation.getMessage(['required'])">
                                        <el-input-number v-model="form.data.count" :min="1" :max="1000"/>
                                    </el-form-item>
                                </div>

                                <template v-if="form.data.type">
                                    <div class="fv-row mb-7">
                                        <label class="required fs-6 fw-bold mb-2">{{ $t('pages.ecommerce.discountCoupon.cols.couponType') }}</label>
                                        <el-form-item prop="discount_type" :rules="$validation.getMessage(['required'])">
                                            <el-select v-model="form.data.discount_type" class="w-100" :placeholder="$t('common.chooseSelect')">
                                                <el-option :value="1" :label="$t('pages.ecommerce.discountCoupon.couponTypes.static')"></el-option>
                                                <el-option :value="2" :label="$t('pages.ecommerce.discountCoupon.couponTypes.percent')"></el-option>
                                            </el-select>
                                        </el-form-item>
                                    </div>

                                    <div class="fv-row mb-7">
                                        <label class="required fs-6 fw-bold mb-2">{{ $t('pages.ecommerce.discountCoupon.cols.value') }}</label>
                                        <el-form-item prop="discount_value" :rules="$validation.getMessage(['required'])">
                                            <el-input v-model="form.data.discount_value" v-mask="{ alias: 'decimal', placeholder: '', allowMinus:false, rightAlign: false }" type="text"/>
                                        </el-form-item>
                                    </div>
                                </template>
                            </template>
                            <el-tabs v-model="tabModel" class="coupon-tabs" v-if="form.updateStatus || form.data.type">
                                <el-tab-pane :label="$t('pages.ecommerce.discountCoupon.tabs.general')" name="general">
                                    <div class="fv-row mb-7">
                                        <label class="fs-6 fw-bold mb-2">{{ $t('pages.ecommerce.discountCoupon.cols.startDate') }}</label>
                                        <el-form-item prop="start_date">
                                            <el-date-picker
                                                class="rangeDateTimePicker"
                                                v-model="form.data.start_date"
                                                type="datetime"
                                                popper-class="rangeDateTimePickerPopper"
                                                :placeholder="$t('common.chooseDateAndTime')"
                                                valueFormat="YYYY-MM-DD HH:mm:ss"
                                                :disabledDate="disabledStartDate"
                                            >
                                            </el-date-picker>
                                        </el-form-item>
                                    </div>

                                    <div class="fv-row mb-7">
                                        <label class="fs-6 fw-bold mb-2">{{ $t('pages.ecommerce.discountCoupon.cols.endDate') }}</label>
                                        <el-form-item prop="end_date">
                                            <el-date-picker
                                                class="rangeDateTimePicker"
                                                v-model="form.data.end_date"
                                                type="datetime"
                                                popper-class="rangeDateTimePickerPopper"
                                                :placeholder="$t('common.chooseDateAndTime')"
                                                valueFormat="YYYY-MM-DD HH:mm:ss"
                                                :disabledDate="disabledEndDate"
                                            >
                                            </el-date-picker>
                                        </el-form-item>
                                    </div>

                                    <div class="fv-row mb-7">
                                        <label class="fs-6 fw-bold mb-2">{{ $t('pages.ecommerce.discountCoupon.cols.minAmount') }}</label>
                                        <el-form-item prop="min_amount">
                                            <el-input v-model="form.data.min_amount" oninput="value=value.replace(/[^0-9.]/g,'')"/>
                                        </el-form-item>
                                    </div>

                                    <div class="row">
                                        <div class="col-md-6 fv-row mb-1">
                                            <label class="fs-6 fw-bold mb-2">{{ $t('pages.ecommerce.discountCoupon.cols.totalUseLimit') }}</label>
                                            <el-form-item prop="total_use_limit">
                                                <el-input v-model="form.data.total_use_limit" oninput="value=value.replace(/[^0-9.]/g,'')" clearable/>
                                            </el-form-item>
                                        </div>
                                        <div class="col-md-6 fv-row mb-1">
                                            <label class="fs-6 fw-bold mb-2">
                                                {{ $t('pages.ecommerce.discountCoupon.cols.userUseLimit') }}
                                                <el-tooltip class="item ms-1" popper-class="max-w-300px" effect="dark" :content="$t('pages.ecommerce.discountCoupon.informationBoxes.userUseLimit')" placement="top">
                                                    <span class="svg-icon svg-icon-1">
                                                        <inline-svg src="/media/icons/duotune/general/gen046.svg"/>
                                                    </span>
                                                </el-tooltip>
                                            </label>
                                            <el-form-item prop="user_use_limit">
                                                <el-input v-model="form.data.user_use_limit" oninput="value=value.replace(/[^0-9.]/g,'')" clearable/>
                                            </el-form-item>
                                        </div>
                                    </div>

                                    <div class="fv-row mb-7">
                                        <label class="fs-6 fw-bold mb-2">{{ $t('pages.ecommerce.discountCoupon.cols.cannotCombined') }}</label>
                                        <el-form-item prop="status">
                                            <el-radio-group v-model="form.data.cannot_combined">
                                                <el-radio-button :label="false">{{ $t("common.yes") }}</el-radio-button>
                                                <el-radio-button :label="true">{{ $t("common.no") }}</el-radio-button>
                                            </el-radio-group>
                                        </el-form-item>
                                    </div>

                                    <div class="fv-row mb-7">
                                        <label class="fs-6 fw-bold mb-2">{{ $t('common.status') }}</label>
                                        <el-form-item prop="status">
                                            <el-radio-group v-model="form.data.active">
                                                <el-radio-button :label="true">{{ $t("common.active") }}</el-radio-button>
                                                <el-radio-button :label="false">{{ $t("common.passive") }}</el-radio-button>
                                            </el-radio-group>
                                        </el-form-item>
                                    </div>
                                </el-tab-pane>

                                <el-tab-pane :label="$t('pages.ecommerce.discountCoupon.tabs.product')" name="product">
                                    <template v-if="tabModel == 'product'">
                                        <div class="fv-row mb-7" v-if="!form.data.unusable_products.length && !form.data.usable_categories.length && !form.data.unusable_categories.length">
                                            <label class="fs-6 fw-bold mb-2">
                                                {{ $t('pages.ecommerce.discountCoupon.cols.usable.usableProduct') }}
                                                <el-tooltip class="item ms-1" popper-class="max-w-300px" effect="dark" :content="$t('pages.ecommerce.discountCoupon.informationBoxes.usable.usableProduct')" placement="top">
                                                    <span class="svg-icon svg-icon-1">
                                                        <inline-svg src="/media/icons/duotune/general/gen046.svg"/>
                                                    </span>
                                                </el-tooltip>
                                            </label>
                                            <el-form-item prop="usable_products">
                                                <el-select v-model="form.data.usable_products" class="w-100" :placeholder="$t('common.chooseSelect')" filterable multiple clearable>
                                                    <el-option v-for="(product, productIndex) in products" :key="productIndex" :value="product.id" :label="product.translate.title + ' (' + product.group.name + ')'"></el-option>
                                                </el-select>
                                            </el-form-item>
                                        </div>
                                        <template v-if="!form.data.usable_products.length">
                                            <div class="fv-row mb-7">
                                                <label class="fs-6 fw-bold mb-2">
                                                    {{ $t('pages.ecommerce.discountCoupon.cols.usable.unusableProduct') }}
                                                    <el-tooltip class="item ms-1" popper-class="max-w-300px" effect="dark" :content="$t('pages.ecommerce.discountCoupon.informationBoxes.usable.unusableProduct')" placement="top">
                                                    <span class="svg-icon svg-icon-1">
                                                        <inline-svg src="/media/icons/duotune/general/gen046.svg"/>
                                                    </span>
                                                    </el-tooltip>
                                                </label>
                                                <el-form-item prop="unusable_products">
                                                    <el-select v-model="form.data.unusable_products" class="w-100" :placeholder="$t('common.chooseSelect')" filterable multiple clearable>
                                                        <el-option v-for="(product, productIndex) in products" :key="productIndex" :value="product.id" :label="product.translate.title + ' (' + product.group.name + ')'"></el-option>
                                                    </el-select>
                                                </el-form-item>
                                            </div>
                                            <div class="fv-row mb-7" v-if="!form.data.unusable_categories.length">
                                                <label class="fs-6 fw-bold mb-2">
                                                    {{ $t('pages.ecommerce.discountCoupon.cols.usable.usableCategory') }}
                                                    <el-tooltip class="item ms-1" popper-class="max-w-300px" effect="dark" :content="$t('pages.ecommerce.discountCoupon.informationBoxes.usable.usableCategory')" placement="top">
                                                        <span class="svg-icon svg-icon-1">
                                                            <inline-svg src="/media/icons/duotune/general/gen046.svg"/>
                                                        </span>
                                                    </el-tooltip>
                                                </label>
                                                <el-form-item prop="usable_categories">
                                                    <el-input type="hidden" v-model="form.data.usable_categories"></el-input>
                                                    <el-select-tree :value="form.data.usable_categories" @input="form.data.usable_categories = $event" :data="treeProductCategories" :placeholder="$t('common.chooseSelect')" :disableBranchNodes="false" openDirection="above" :maxHeight="150"/>
                                                </el-form-item>
                                            </div>
                                            <div class="fv-row mb-7" v-if="!form.data.usable_categories.length">
                                                <label class="fs-6 fw-bold mb-2">
                                                    {{ $t('pages.ecommerce.discountCoupon.cols.usable.unusableCategory') }}
                                                    <el-tooltip class="item ms-1" popper-class="max-w-300px" effect="dark" :content="$t('pages.ecommerce.discountCoupon.informationBoxes.usable.unusableCategory')" placement="top">
                                                        <span class="svg-icon svg-icon-1">
                                                            <inline-svg src="/media/icons/duotune/general/gen046.svg"/>
                                                        </span>
                                                    </el-tooltip>
                                                </label>
                                                <el-form-item prop="unusable_categories">
                                                    <el-input type="hidden" v-model="form.data.unusable_categories"></el-input>
                                                    <el-select-tree :value="form.data.unusable_categories" @input="form.data.unusable_categories = $event" :data="treeProductCategories" :placeholder="$t('common.chooseSelect')" :disableBranchNodes="false" openDirection="above" :maxHeight="150"/>
                                                </el-form-item>
                                            </div>
                                        </template>
                                    </template>
                                </el-tab-pane>
                                <el-tab-pane :label="$t('pages.ecommerce.discountCoupon.tabs.user')" name="user">
                                    <template v-if="tabModel == 'user'">
                                        <div class="fv-row mb-7">
                                            <label class="fs-6 fw-bold mb-2">
                                                {{ $t('pages.ecommerce.discountCoupon.cols.users') }}
                                                <el-tooltip class="item ms-1" popper-class="max-w-300px" effect="dark" :content="$t('pages.ecommerce.discountCoupon.informationBoxes.user')" placement="top">
                                                    <span class="svg-icon svg-icon-1">
                                                        <inline-svg src="/media/icons/duotune/general/gen046.svg"/>
                                                    </span>
                                                </el-tooltip>
                                            </label>
                                            <el-form-item prop="users">
                                                <el-select
                                                    class="w-100"
                                                    v-model="form.data.users"
                                                    :filterable="user.filterable"
                                                    clearable
                                                    remote
                                                    multiple
                                                    :remote-method="remoteSearchUser"
                                                    :loading="user.loading"
                                                    :placeholder="$t('common.chooseSelect')">
                                                    <el-option v-for="(user, userIndex) in user.list" :key="userIndex" :value="user.id" :label="user.full_name + ' (' + user.email +')'"></el-option>
                                                </el-select>
                                            </el-form-item>
                                        </div>
                                    </template>
                                </el-tab-pane>
                            </el-tabs>
                        </div>
                    </div>

                    <div class="modal-footer flex-center">
                        <button :data-kt-indicator="form.loading ? 'on' : null" class="btn btn-lg btn-primary" type="submit" :disabled="form.loading">
                            <span v-if="!form.loading" class="indicator-label">{{ $t("btn.save") }}</span>
                            <span v-if="form.loading" class="indicator-progress">
                                {{ $t("messages.wait") }}
                                <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                            </span>
                        </button>
                    </div>
                </el-form>
            </div>
        </div>
    </div>
</template>

<script>
import CustomTable from "@/components/custom-table";
import ElSelectTree from "@/components/ElSelectTree.vue";

export default {
    name: "index",
    components: {
        CustomTable,
        ElSelectTree
    },
    data() {
        return {
            fields: [
                {
                    name: this.$t("common.id"),
                    key: "id",
                    class: ""
                },
                {
                    name: this.$t("pages.ecommerce.discountCoupon.cols.code"),
                    key: "code"
                },
                {
                    name: this.$t("pages.ecommerce.discountCoupon.cols.couponType"),
                    scopedSlots: {customRender: "type"},
                },
                {
                    name: this.$t("pages.ecommerce.discountCoupon.cols.value"),
                    scopedSlots: {customRender: "value"},
                },
                {
                    name: this.$t("pages.ecommerce.discountCoupon.cols.used"),
                    key: "orders_count"
                },
                {
                    name: this.$t('pages.ecommerce.discountCoupon.cols.startDate'),
                    scopedSlots: {customRender: "start_date"}
                },
                {
                    name: this.$t('pages.ecommerce.discountCoupon.cols.endDate'),
                    scopedSlots: {customRender: "end_date"}
                },
                {
                    name: this.$t("common.status"),
                    scopedSlots: {customRender: "status"}
                },
                {
                    name: this.$t('common.createdAt'),
                    scopedSlots: {customRender: "created_at"}
                },
                {
                    name: this.$t("common.action"),
                    key: "action",
                    scopedSlots: {customRender: "actions"}
                }
            ],
            actions: [
                {
                    name: "new",
                    icon: "bi-plus-lg",
                    label: "btn.add"
                },
                {
                    name: "refresh",
                    icon: "bi-arrow-repeat",
                    label: "btn.refresh"
                },
                {
                    name: "delete",
                    icon: "bi-trash",
                    label: "btn.delete"
                }
            ],
            form: {
                title: '',
                loading: false,
                updateStatus: false,
                data: {}
            },
            selectedRowKeys: [],
            filterParameters: {},
            exportLoading: false,
            types: {
                1: "static",
                2: "percent",
            },
            user: {
                filterable: true,
                loading: false,
                selectedUsers: [],
                list: []
            },
            tabModel: "general",
            treeProductCategories: []
        }
    },
    computed: {
        table() {
            return this.$store.state.ecommerce.discountCoupon.table;
        },
        products() {
            return this.$store.state.ecommerce.productManagement.product.indexRaw.table.data.map((product) => {
                product.translate = this.resolveDatum(product.translations, this.$root.defaultLanguage.id, 'language_id')

                return product;
            })
        },
        /*users(){
            return this.$store.state.ecommerce.user.table.data;
        }*/
    },
    mounted() {
        this.setCurrentPageBreadcrumbs(this.$t("menu.discountCouponManagement.coupons"), [this.$t("menu.ecommerceManagement")]);

        this.filterParameters = {
            sort: 'created_at:desc',
        };

        this.$store.dispatch('ecommerce/discountCoupon/get', {
            page: {},
            filterData: Object.assign(this.$root.getFilterWithUrl({}), this.filterParameters)
        });

        this.$store.dispatch('ecommerce/productManagement/product/indexRaw/get', {
            page: {pageSize: 9999}
        });

        this.loadTreeProductCategories();

        /*this.$store.dispatch('ecommerce/user/get', {
            page: {pageSize: 9999}
        });*/
    },
    methods: {
        loadTreeProductCategories() {
            this.axios.get(this.endpoints['ecommerce_product_category'] + '/tree').then((response) => {
                this.treeProductCategories = response.data.data;
            });
        },
        generateCode() {
            this.axios.get(this.endpoints['ecommerce_discount_coupon_generate_code'], {
                params: {
                    limit: 8
                }
            }).then(response => {
                if (response.data.status) {
                    this.form.data.code = response.data.data.code;
                }
            });
        },
        newDiscountCoupon() {
            this.form.updateStatus = false;
            this.form.data = {
                discount_type: 1,
                cannot_combined: true,
                active: true,
                count: 1,
                usable_products: [],
                unusable_products: [],
                usable_categories: [],
                unusable_categories: []
            };
            this.form.title = this.$t("pages.ecommerce.discountCoupon.newDiscountCoupon");
            this.tabModel = 'general';

            this.generateCode();
            this.showModal(this.$refs.addDiscountCoupon);
        },
        fetchDiscountCoupon(record) {
            this.form.updateStatus = true;
            this.form.title = this.$t("pages.ecommerce.discountCoupon.editDiscountCoupon");
            this.axios.get(this.endpoints['ecommerce_discount_coupon'] + '/' + record.id).then(response => {
                let data = response.data.data;

                data.start_date = data.start_date ? this.$moment(data.start_date).format('YYYY-MM-DD HH:mm:ss') : undefined;
                data.end_date = data.end_date ? this.$moment(data.end_date).format('YYYY-MM-DD HH:mm:ss') : undefined;

                if (data.users && data.users.length) {
                    this.axios.get(this.endpoints['user'], {
                        params: {
                            ids: data.users
                        }
                    }).then(response => {
                        let data = response.data.data;
                        this.user.list = data;
                    });
                }

                this.form.data = data;

                this.tabModel = 'general';

                this.showModal(this.$refs.addDiscountCoupon);
            });
        },
        onSubmit() {
            this.$refs.discountCouponForm.validate((valid) => {
                if (valid) {
                    let formData = this.prepareDiscountCouponFormData();

                    if (formData.total_use_limit && formData.user_use_limit && formData.user_use_limit > formData.total_use_limit) {
                        this.$notify({
                            type: 'warning',
                            title: this.$t("messages.warning"),
                            message: this.$t("pages.ecommerce.discountCoupon.warnings.userUseLimit"),
                        })

                        return false;
                    }

                    this.form.loading = true;

                    if (this.form.data.id) {
                        this.axios.put(this.endpoints['ecommerce_discount_coupon'] + '/' + this.form.data.id, formData).then(response => {
                            this.onResponse(response.data, () => {
                                this.refreshTable();
                                this.hideModal(this.$refs.addDiscountCoupon);
                            }, () => {
                                this.form.loading = false;
                            });
                        }).catch(error => {
                            this.onResponseFailure(error.response.data, () => {
                                this.form.loading = false;
                            });
                        });
                    } else {
                        if (this.form.data.type === 'SINGLE') {
                            this.axios.post(this.endpoints['ecommerce_discount_coupon'], formData).then(response => {
                                this.onResponse(response.data, () => {
                                    this.refreshTable();
                                    this.hideModal(this.$refs.addDiscountCoupon);
                                }, () => {
                                    this.form.loading = false;
                                });
                            }).catch(error => {
                                this.onResponseFailure(error.response.data, () => {
                                    this.form.loading = false;
                                });
                            });
                        }

                        if (this.form.data.type === 'MULTI') {
                            this.axios({
                                url: this.endpoints['ecommerce_discount_coupon'],
                                method: 'post',
                                responseType: "blob",
                                headers: {'Content-Type': 'application/json'},
                                data: formData
                            }).then(response => {
                                const type = response.headers['content-type'];

                                if (type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
                                    response.data.status = true;
                                }

                                this.onResponse(response.data, () => {
                                    this.refreshTable();
                                    this.hideModal(this.$refs.addDiscountCoupon);

                                    const blob = new Blob([response.data], {type: type, encoding: 'UTF-8'})
                                    const link = document.createElement('a')
                                    link.href = window.URL.createObjectURL(blob)
                                    link.click();
                                }, () => {
                                    this.form.loading = false;
                                });
                            }).catch(error => {
                                this.onResponseFailure(error.response.data, () => {
                                    this.form.loading = false;
                                });
                            });
                        }
                    }
                } else {
                    return false;
                }
            });
        },
        prepareDiscountCouponFormData() {
            let formData = this.cloneData(this.form.data);

            formData.total_use_limit = parseInt(formData.total_use_limit);
            formData.user_use_limit = parseInt(formData.user_use_limit);

            if (formData.type == 'SINGLE') {
                delete formData.count;
            }

            if (formData.type == 'MULTI') {
                delete formData.code;
            }

            return formData
        },
        deleteRecord(id) {
            this.$store.dispatch("ecommerce/discountCoupon/delete", {
                id: id
            }).then((successDeleted) => {
                this.selectedRowKeys = this.selectedRowKeys.filter(x => !successDeleted.includes(x));
            });
        },
        handleClickAction(name) {
            switch (name) {
                case "refresh":
                    this.refreshTable();
                    break;

                case "new":
                    this.newDiscountCoupon();
                    break;

                case "delete":
                    this.deleteRecord(this.selectedRowKeys.flat());
                    break;

                default:
                    break;
            }
        },
        refreshTable() {
            this.$store.dispatch("ecommerce/discountCoupon/refresh");
        },
        handleTableChange(pagination, filterData) {
            this.filterParameters = Object.assign({
                sort: 'created_at:desc',
            }, filterData);

            this.$store.dispatch("ecommerce/discountCoupon/get", {
                page: pagination,
                filterData: this.filterParameters
            });
        },
        handleSelectedRow(record) {
            this.selectedRowKeys = record;
        },
        disabledStartDate(startDate) {
            startDate = this.$moment(startDate);
            const endDate = this.form.data.end_date ? this.$moment(this.form.data.end_date) : undefined;

            return this.$moment().add(-1, 'days').valueOf() > startDate.valueOf() || (endDate != undefined && startDate.valueOf() > endDate.valueOf());
        },
        disabledEndDate(endDate) {
            endDate = this.$moment(endDate);
            const startDate = this.form.data.start_date ? this.$moment(this.$moment(this.form.data.start_date).format('YYYY-MM-DD')) : this.$moment().add(-1, 'days');
            return startDate.valueOf() > endDate.valueOf();
        },
        exportDiscount() {
            this.exportLoading = true;
            this.axios({
                url: this.endpoints['ecommerce_discount_coupon_export'],
                method: 'get',
                responseType: "blob",
                params: this.filterParameters
            }).then(response => {
                const type = response.headers['content-type']
                const blob = new Blob([response.data], {type: type, encoding: 'UTF-8'})
                const link = document.createElement('a')
                link.href = window.URL.createObjectURL(blob)
                link.click();
            }).finally(() => {
                this.exportLoading = false;
            });
        },
        remoteSearchUser(query) {
            if (query) {
                this.user.loading = true;
                this.axios.get(this.endpoints['user'], {
                    params: {
                        magic: query
                    }
                }).then(response => {
                    let data = response.data.data;
                    let userList = [];
                    let userListIDs = [];

                    if (this.form.data.users && this.form.data.users.length) {
                        this.user.list.forEach((user) => {
                            if (this.form.data.users.includes(user.id)) {
                                userList.push(user);
                                userListIDs.push(user.id);
                            }
                        });
                    }

                    data.forEach((user) => {
                        if (!userListIDs.includes(user.id)) {
                            userList.push(user);
                        }
                    })

                    this.user.list = userList;
                }).finally(() => {
                    this.user.loading = false;
                });
            } else {
                this.user.list = [];
            }
        },
    },
    beforeRouteLeave(to, from, next) {
        this.$store.dispatch('ecommerce/user/flush', {});
        next();
    }
}
</script>

<style>
.el-date-editor.el-input,
.el-date-editor.el-input__inner {
    width: 100%;
}

.rangeDateTimePickerPopper .el-button--text.el-picker-panel__link-btn {
    display: none;
}

.code-form-row .el-form-item {
    margin-bottom: 10px !important;
}

.coupon-tabs .el-tabs__header {
    margin-bottom: 0;
}

.coupon-tabs .el-tabs__content {
    padding-top: 35px;
}
</style>